import React, {useEffect, useState} from 'react';
import {useLoaderData} from "react-router-dom";
import {Topic} from "../models/Topic";
import {
    TopicInfo,
    LatestVideos,
    TopicChannelsRating,
    RecommendedVideos,
    TopicStats,
    RecommendedChannels, DataNotification
} from "../modules/topic";
import PeriodVideoRating from "../components/PeriodVideoRating";
import {overallVideosRating} from "../http/rating";
import WideCard from "../ui/WideCard";
import {useTranslation} from "react-i18next";
import Changes from "../components/Changes";
import Breadcrumbs from "../components/Breadcrumbs";
import ToggleShorts from "../components/ToggleShorts";
import {useShortsStore} from "../store/shorts";
import {topicInfo} from "../modules/topic/http";

function TopicPage() {
    const initialTopic: Topic = useLoaderData() as Topic;

    const {shorts} = useShortsStore();

    const [topic, setTopic] = useState<Topic>();

    useEffect(() => {
        setTopic(initialTopic);
    }, [initialTopic]);

    useEffect(() => {
       topicInfo(initialTopic.id, shorts).then((res) => {
           setTopic(res.data);
       });
    }, [shorts]);

    const {t} = useTranslation();

    if (!topic) {
        return <></>;
    }

    return (
        <div className="pt-8 pb-8">
            <Breadcrumbs pages={[
                {
                    title: `${t("breadcrumbs.topic")}: ${topic.title}`,
                    link: ""
                }
            ]} />
            <ToggleShorts/>
            <TopicInfo topic={topic} />
            <RecommendedVideos topic={topic} />
            {(!!topic.recommended_channels && topic.recommended_channels.length !== 0) &&
                <RecommendedChannels channels={topic.recommended_channels} topic={topic} setTopic={setTopic} />
            }
            <LatestVideos topic={topic} />
            <TopicStats topic={topic} shorts={shorts} />
            <WideCard className="mt-8 mb-8 overflow-visible">
                <div className="text-lg font-bold text-center mb-8">{t("channel_page.video_rating.heading")}</div>
                <PeriodVideoRating topic={topic} shorts={shorts} requestRating={overallVideosRating} />
            </WideCard>
            <WideCard className="mt-8 mb-8">
                <div className="text-lg font-bold text-center mb-8">{t("topic_page.changes.heading")}</div>
                <Changes topic={topic} shorts={shorts} />
            </WideCard>
            <TopicChannelsRating topic={topic} shorts={shorts} />
            <DataNotification topic={topic}/>
        </div>
    );
}

export default TopicPage;