import React, {useMemo} from 'react';
import {Topic} from "../../../models/Topic";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import TopicStatsCard from "./TopicStatsCard";
import {Plan} from "../../../models/Plan";
import {useModalStore} from "../../../store/modal";
import {useShortsStore} from "../../../store/shorts";

type TopicStatsProps = {
    topic: Topic
    shorts: boolean
}

type Field = "avg_views" | "sum_views" | "median_vs" | "median_erv" | "median_score" | "current_videos" | "median_subscriptions" | "median_length"

function TopicStats({topic, shorts}: TopicStatsProps) {
    const {t} = useTranslation();

    const columns = useMemo(() => {
        if (shorts) {
            return [
                "avg_views_shorts",
                "sum_views_shorts",
                "median_vs_shorts",
                "median_erv_shorts",
                "current_videos_shorts",
                "median_length_shorts",
                "median_subscriptions",
            ];
        }

        return [
            "avg_views",
            "sum_views",
            "median_vs",
            "median_erv",
            "current_videos",
            "median_length",
            "median_subscriptions",
        ];
    }, [shorts])

    const showLocks = useMemo(() => {
        if (!localStorage.getItem("plan")) return true;
        const plan = JSON.parse(localStorage.getItem("plan")!) as Plan;
        if (!plan) return true;
        return plan.is_trial || (plan.channel_count == 25 && shorts);
    }, [shorts])

    const {setSubscribeModalOpen} = useModalStore();

    return (
        <WideCard>
            <div className="text-center text-lg font-bold mb-8">{t("topic_page.topic_stats_heading")}</div>
            <div className="grid grid-cols-2 gap-8">
                {columns.map((col, i) => {
                    return <TopicStatsCard
                        topic={topic}
                        field={col as Field}
                        key={i}
                        blocked={showLocks}
                        onClick={showLocks ? () => {setSubscribeModalOpen(true, {limit: false, trial: true})} : undefined}
                    />;
                })}
            </div>
        </WideCard>
    );
}

export default TopicStats;